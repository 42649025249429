import BarChart from "../charts/BarChart";
import LineChart from "../charts/LineChart";
import PieChart from "../charts/PieChart";
import Page from "../Page";
import Title1 from "../Title1";
import Title2 from "../Title2";
import RowCharts from "../RowCharts";

//Elemento dinamico
//  Va desde una pagina hasta los graficos que la componen
const DinamicElement = (props) => {
    if (props.dinamicContent.elementType===undefined)
        return ""

    switch (props.dinamicContent.elementType) {
        case 'Page':
            return <Page {...props.dinamicContent.data} /> 
        case 'Title1':
            return <Title1 {...props.dinamicContent.data} />              
        case 'Title2':
            return <Title2 {...props.dinamicContent.data}  /> 
        case 'RowCharts':
            return <RowCharts {...props.dinamicContent.data} updateSelectedChart={props.updateSelectedChart}/> 
        case 'LineChart':
            return <LineChart {...props.dinamicContent.data} updateSelectedChart={props.updateSelectedChart} /> 
        case 'PieChart':
            
            return <PieChart {...props.dinamicContent.data} updateSelectedChart={props.updateSelectedChart} /> 
        case 'BarChart':
            return <BarChart {...props.dinamicContent.data} updateSelectedChart={props.updateSelectedChart}/> 
        default:
            return ""
    }
}
 
export default DinamicElement;