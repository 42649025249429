import "./NavegadorTradicional.css";
import React, { useState,useEffect } from "react";
import { Col } from "react-bootstrap";
import chartManager from "../../hooks/chartManager";

const NavegadorTradicional = ( props ) => {
    const { Lista, SetId  } = props;
    const [ Data, setData ] = useState([]);

    chartManager.GetChartsInfo(Lista,setData);

    const IrAGrafico = (e) => {
   //    alert(e)
        SetId(e);
     //   setData(Lista)


//VERSION TOMAS
    //   SetPantalla({
    //     busqueda: true, 
    //     chart: e.ChartId, // Aca en version final un listado aleatorio de gráficos
    // });
    } 


    useEffect(() => {

    }, []); // Only re-run the effect if count changes       


    useEffect(() => {
     //   alert("En setdata")
        if (Data.length>0)
        {
            SetId(Data[0]._id)
         //   alert(Data[0]._id )
        }
     
    }, [setData]); // Only re-run the effect if count changes       




    var i=0

    console.log("Data-----------------------------------------------------")
    console.log(Data)
    Data.forEach((x, index) =>
    {

        console.log(x)
    })

    // for(let [key, value] of Data) {
    //     console.log(key, value)
    //   }
    if(Data===[])
    {
                    return  <>      
                    <div> Ops! sin resultados, intente otra consulta... 3,2,1.</div>    
                    <div> No  es array</div>
                    </>
                }

    return  <>     
        <div className="NavegadorTradicional">
        <Col md="6" xl="12">
            <div className="center-col">      

                
                
                
                {
                
                Data.map((x, index) => 
                {
                        
                    if(x===null)
                    {
                        return  <>      
                        <div> Ops! sin resultados, intente otra consulta... 3,2,1.</div>    
                        <div> No  es array</div>
                        </>
                    }


                        return  <div className={"NavegadorTradicional-linkOption w-100 " + (x._id.ChartSelected ? "bg-info" : "")} onClick={() => { IrAGrafico(x._id)}} key={"NavegadorTradicional-item-"+index}>
                        {/* ACA UPDATE por consistencia de cHeaderen  return  <div className="NavegadorTradicional-linkOption" onClick={() => { IrAGrafico(Lista[i])}} key={"NavegadorTradicional-item-"+i}> */}
                                    <div className="NavegadorTradicional-linkText">{x.cHeader}</div>
                                    <div className="NavegadorTradicional-linkDescription">{x.cText}</div>
                                  {/*   <div className="NavegadorTradicional-linkDescription">{x._id}</div> */}
                                </div>
                        
                }
                )}
                
            </div>
        </Col>
        
        
        
        </div>
        </>;


}

export default NavegadorTradicional;