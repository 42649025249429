import React , { useEffect, useState, useRef } from "react";
import { Outlet, useLocation } from "react-router-dom";
import Sidebar from "./Sidebar";
import Header from "./Header";
import SearchEngine from "../hooks/SearchEngine";
/* import { Button, Card, Col, Nav, ProgressBar, Row } from "react-bootstrap";
import Proyectos from "../dashboard/Proyectos"; */
import DescargaPlanillas from "../components/DescargaPlanillas";
import DatabaseMenu from "../dashboard/DatabaseMenu";
import Proyectos from "../dashboard/Proyectos";
import {MyEstadoGlobalContext} from './MyEstadoGlobalContext';
import background from "./img/static/container-media@1x.png";
/* import { Link, useParams } from "react-router-dom";
 */
import Home from "../home/Home";


import {CentralSE} from "./CentralSE";
let respuestaQ = []


export default function Main(props) {
  const currentSkin = (localStorage.getItem('skin-mode')) ? 'dark' : '';
  const [skin, setSkin] = useState(currentSkin);
  const {origen,consulta} = props;

  const [origenIn, setOrigenIn] = useState(origen);
  const [idq, setIdq] = useState([]);
    //const [pantalla, SetPantalla] = useState("random");
    // const [pantalla, SetPantalla] = useState(
    //   {
    //     busqueda: 'random', 
    //     chart: ["1001210e48a8a8803a3fa000","63a0b027889a9418bd52d500"], // Aca en version final un listado aleatorio de gráficos
    //   },);
  const [pantalla4, setPantalla4] = useState("proyectos 2020")    
//  const [respuesta, setRespuesta] = useState([])
  //const [mostrar, setMostrar] = React.useState(false);
  
  const {mostrarBD, setMostrarBD} = React.useContext(MyEstadoGlobalContext);
  const {mostrarWSE, setMostrarWSE} = React.useContext(MyEstadoGlobalContext);
  const {mostrarHome, setMostrarHome} = React.useContext(MyEstadoGlobalContext);
  const {mostrarAyuda, setMostrarAyuda} = React.useContext(MyEstadoGlobalContext);
  const {mostrarPI, setMostrarPI} = React.useContext(MyEstadoGlobalContext);
  const {mostrarProyectos, setMostrarProyectos} = React.useContext(MyEstadoGlobalContext);

  //let respuesta = SearchEngine(pantalla4);
   const respuesta = SearchEngine(pantalla4);
  // respuestaQ = respuesta;
 // const respuesta = '64713bcea05e492ff38d3c33'
  // console.log("busqueda XXX1: "+ pantalla4)  
  // console.log("busqueda XXX2: "+ respuesta) 
  // console.log("OrigenIn:"+ origenIn) 
  // console.log("Origen:"+ origen) 
  // //alert("R: "+respuesta)


  // ACA AGREGAR CODE DE SKIN-VIEWPORT
  const switchSkin = (skin) => {
    if (skin === 'dark') {
      const btnWhite = document.getElementsByClassName('btn-white');

      for (const btn of btnWhite) {
        btn.classList.add('btn-outline-primary');
        btn.classList.remove('btn-white');
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName('btn-outline-primary');

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove('btn-outline-primary');
        btn.classList.add('btn-white');
      }
    }
  };

  switchSkin(skin);
  useEffect(() => {
    switchSkin(skin);
    // contador = contador + 1;
    // console.log("C en skin"+contador) 
  }, [skin]);

/*   useEffect(() => {

    setMostrarWSE(true)
    setMostrarBD(false)
  }, [setMostrarWSE,setMostrarBD]);

 */


  // useEffect(() => {  
  //   /*     const setWSE = () => {setMostrarWSE(true)}
  //       const setBD = () => {setMostrarBD(false)}
  //       setWSE();
  //       setBD(); */
    
  //     //  alert("en iq: "+respuestaQ)
  //      setPantalla4("Proyectos 2019")
  //      // setMostrarBD(false)
  //     }, [consulta]); // Only re-run the effect if count changes

  useEffect(() => {  
/*     const setWSE = () => {setMostrarWSE(true)}
    const setBD = () => {setMostrarBD(false)}
    setWSE();
    setBD(); */

  //  alert("en iq: "+respuestaQ)
   setMostrarWSE(true)
   // setMostrarBD(false)
  }, [pantalla4,setMostrarWSE,setMostrarBD]); // Only re-run the effect if count changes

//   useEffect(() => {  
//     /*     const setWSE = () => {setMostrarWSE(true)}
//         const setBD = () => {setMostrarBD(false)}
//         setWSE();
//         setBD(); */
//        // setMostrarWSE(true)
//       //  setMostrarBD(false)
//      // alert("en iq: "+idq)
//      // let oids = Object.values(idq)
//       //respuestaQ = [idq];
//  //     alert("en iq: "+respuestaQ)
//    //   setRespuesta(idq)
//      // respuestaQ = respuesta;
//       }, [idq,respuesta]); // Only re-run the effect if count changes
    

  const offsets = ["/apps/file-manager", "/apps/email", "/apps/calendar"];
  const { pathname } = useLocation();
  const bc = document.body.classList;

  // set sidebar to offset
  (offsets.includes(pathname)) ? bc.add("sidebar-offset") : bc.remove("sidebar-offset");

  // auto close sidebar when switching pages in mobile
  bc.remove("sidebar-show");

  // scroll to top when switching pages
  window.scrollTo(0, 0);

  
///alert("llamada")

useEffect(() => {  
  /*     const setWSE = () => {setMostrarWSE(true)}
      const setBD = () => {setMostrarBD(false)}
      setWSE();
      setBD(); */
     // setMostrarWSE(true)
    //  setMostrarBD(false)
   // alert("en iq: "+idq)
   // let oids = Object.values(idq)
    //respuestaQ = [idq];
//     alert("en iq: "+respuestaQ)
 //   setRespuesta(idq)
   // respuestaQ = respuesta;
   setMostrarWSE(false)
   setMostrarBD(false)
   setMostrarPI(false)
setMostrarHome(true)
setMostrarProyectos(false)
setMostrarAyuda(false)

    }, []); // Only re-run the effect if count changes

  

  return (
   
      <React.Fragment> 
      
      <Header onSkin={setSkin} setPantalla4={(e) => {setPantalla4(e)}}  setIdq = {(e) => {setIdq(e)}} setOrigenIn={setOrigenIn} />  
      {/* <Header onSkin={setSkin} setPantalla4={(e) => {setPantalla4(e)}} setOrigenIn={setOrigenIn}  />        */}
    
      <Sidebar />      
{/*  <CentralSE respuestas={respuesta} idIn = {idq}   consulta={pantalla4} procedencia={origenIn} setIdq = {(e) => {setIdq(e)}}/> 
 */}
      {mostrarWSE &&<CentralSE respuestas={respuesta} idIn = {idq}   consulta={pantalla4} procedencia={origenIn} origenIn={origenIn}  setIdq = {(e) => {setIdq(e)}}/>} 
       {/*  {mostrarHome &&<Home origenIn={origenIn} />} 
    {mostrarWSE &&<CentralSE respuestas={respuesta} idIn = {idq}   consulta={pantalla4} procedencia={origenIn} origenIn={origenIn}  setIdq = {(e) => {setIdq(e)}}/>}  
      {mostrarBD && <> <DatabaseMenu consulta={pantalla4} origenIn={origenIn} />  </>}*/}


     {/*  {<DatabaseMenu consulta={pantalla4} origenIn={origenIn} />  }  */}
      {/* {mostrarBD && <> <DatabaseMenu consulta={pantalla4} origenIn={origenIn} />  </>} 
      {mostrarBD && <> <DatabaseMenu consulta={pantalla4} origenIn={origenIn} />  </>}  */}




 {/*       {/* {mostrarBD && <DatabaseMenu consulta={pantalla4} origenIn={origenIn} />}   */}
{/*  <DatabaseMenu consulta={pantalla4} origenIn={origenIn} />*/}
         {/* {<div style={{ backgroundImage: `url(${background})`,backgroundRepeat:"no-repeat" ,backgroundSize:"contain"}}><Outlet />       </div>  } 
        <Outlet />   
    <Proyectos  origenIn={origenIn} />*/}

<Outlet /> 
     </React.Fragment> 

  )
}