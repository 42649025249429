import React from "react";
import { Line } from "react-chartjs-2";
import { Card } from "react-bootstrap";
import { Chart as ChartJS } from "chart.js/auto";
import "./charts.css";




//Grafico de linea
function LineChart( chartData ) {

  const options =  {
    responsive: true,
    interaction: {
    mode: 'index',
    intersect: false,
  },
    maintainAspectRatio: true,
    plugins: {
       datalabels: {
      display: false
    },
        //  datalabels: {
        //   align: function(context) {
        //     var index = context.dataIndex;
        //     var value = context.dataset.data[index];
        //   //  var invert = Math.abs(value) <= 1;
        // // alert(value)
        //     return value
        //   },
        //  formatter: function(value,context) {
        //   let sum = 0;
        //   let dataArr = context.dataset.data;
        //   dataArr.map(data => {
        //     sum += data;
        // });
        //    return Math.round(value*100/sum) + '%';
        //  },
        // },
        legend: {
            display: true,
            position: "top",
            align:"start"          
    },
  },}


  return (
    <Card  className={"chart-back"}>
    <Card.Header className={"card-text"}>{chartData.cHeader}</Card.Header>
    <Card.Body>
      <Card.Title className={"card-text"}>{chartData.cTitle}</Card.Title>
      <Line data={chartData} options={options}  />
         <Card.Text className={"card-text"}>{chartData.cText}</Card.Text>
        
    </Card.Body>
  </Card>  
  );
}

export default LineChart;
