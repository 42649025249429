import React, { useEffect, useState } from "react";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import { Link } from "react-router-dom";
import { Button, Card, Row, Col, Nav, ListGroup, Table,ProgressBar,Form } from "react-bootstrap";
import ReactApexChart from "react-apexcharts";
import { d1, d2, dp1, dp2, dp3, ciencias ,ingenieria,humanidades,educacion,servsociales,cienciassociales,agricultura,totales} from "../data/DashboardData";
import { Bar,Doughnut } from 'react-chartjs-2';


import CardsGraficos from "../components/CardsGraficos";
import NavegadorTradicional from "../components/NavegadorTradicional";
import UserManager from "../functions/SessionManager";
import pdfgenerator from "../hooks/pdfgeneratorManager";


import crypto1 from "../assets/img/crypto1.jpg";
import crypto2 from "../assets/img/crypto2.jpg";
import crypto3 from "../assets/img/crypto3.jpg";

import {MyEstadoGlobalContext} from '../layouts/MyEstadoGlobalContext';
import "cryptofont/css/cryptofont.css";

import {Chart,Tooltip} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
//Grafico circular percentajes


Chart.register([Tooltip])

// Root Component to demonstrate usage of wrapper component
export default function Proyectos(props) {



  var chart = {
    parentHeightOffset: 0,
    toolbar: { show: true },
    stacked: true
  };

  var grid = {
    borderColor: 'rgba(72,94,144, 0.07)',
    padding: {
      top: -20,
      left: -20,
      right: -20,
      bottom: 0
    },
    yaxis: {
      lines: { show: false }
    }
  };

  var stroke = {
    curve: 'straight',
    width: 1.5
  };

  var fill = {
    type: 'gradient',
    gradient: {
      opacityFrom: 0.5,
      opacityTo: 0,
    }
  };

  var yaxis = {
    min: 0,
    max: 80,
    show: false
  }

  function setOption(color, min, max) {
    return {
      chart: chart,
      colors: [color],
      grid: grid,
      stroke: stroke,
      xaxis: {
      //  categories: ['2018', '2019', '2020', '2021'],

       type: 'numeric',
        tickAmount:4,
        min: min,
        max: max,
        decimalsInFloat: 0, 
        axisBorder: { show: true },
        labels: {
          style: {
            colors: '#009ace',
            fontSize: '14px'
          }
        },
      },
       fill: fill,
      dataLabels: { enabled: true },
      legend: { show: true },
      tooltip: { enabled: true }
    };
  };






  // Tickets Earnings
  const seriesTabla5 = [{
    name: 'Hombres',
    data: d1
  }, {
    name: 'Mujeres',
    data: d2
  }];



  const seriesOne = [{
    name: 'Ciencia de la Salud',
    data: ciencias
  }, {
    name: 'Ingeniería, Industria y Construcción',
    data: ingenieria
  }, {
    name: 'Humanidades y Artes',
    data: humanidades
  }, {
    name: 'Educación',
    data: educacion
  }, {
    name: 'Salud y Servicios Sociales',
    data: servsociales
  }, {
    name: 'Ciencias Sociales, Enseñanza Comercial y Derecho',
    data: cienciassociales
  }, {
    name: 'Agricultura',
    data: agricultura
  }, {
    name: 'Totales',
    data: totales
  }];


  
  const optionOne = {
    chart: {
      parentHeightOffset: 0,
      stacked: true,
      toolbar: { show: false }
    },
    colors: ['#0dcaf0', '#85b6fe', '#506fd9'],
    grid: {
      borderColor: 'rgba(72, 94, 144, .1)',
      padding: {
        top: -20
      }
    },
    dataLabels: { enabled: false },
    stroke: {
      curve: 'straight',
      width: 1.5
    },
    xaxis: {
      type: 'numeric',
      tickAmount: 6,
      axisBorder: { show: false },
      labels: {
        style: {
          colors: '#6e7985',
          fontSize: '11px'
        }
      }
    },
    yaxis: {
      min: 0,
      max: 140,
      tickAmount: 6,
      labels: {
        style: {
          colors: '#6e7985',
          fontSize: '11px'
        }
      }
    },
    fill: {
      type: 'gradient',
      gradient: {
        opacityFrom: 0.5,
        opacityTo: 0,
      }
    },
    legend: { show: false },
    tooltip: { enabled: false }
  }

  // Volume By Currency - Falta agregar los ultimos dos estadisticos!
  var dataPie = {
    labels: ['Dirección de Institutos', 'Coordinación de Institutos', 'Centros de Transferencia- Emprendimiento de la UA','Coordinación de Centros de Transferencia- Emprendimiento de la UA' ,'Vicerrectorías I+D+i', 'Direcciones VRID', 'Proyectos de Investigación', 'Proyectos de Innovación'],
    datasets: [{
      data: [4, 7, 2, 2,1, 3, 14,3],
     // backgroundColor: ['#506fd9', '#85b6ff', '#33d685', '#0dcaf0', '#1c96e9', '#6e7985', '#ccd2da']
      backgroundColor: ['#009ace', '#44d62c', '#ffaa4d', '#ff7276', '#ea27c2', '#00b796', '#fdd635','#bd53ea','#8a69d4']


    }]
  };

  // var optionPie = {
  //   maintainAspectRatio: false,
  //   responsive: true,
  //   plugins: {
  //     legend: {
  //       display: false,
  //     }
  //   },
  //   animation: {
  //     animateScale: true,
  //     animateRotate: true
  //   }
  // };
  var optionPie = {
  
    responsive: true,
    interaction: {
      mode: 'index',
      intersect: false,
    },
    maintainAspectRatio: true,
    plugins: {
         datalabels: {
          align: function(context) {
            var index = context.dataIndex;
            var value = context.dataset.data[index];
          //  var invert = Math.abs(value) <= 1;
        // alert(value)
            return value
          },
         formatter: function(value,context) {
          let sum = 0;
          let dataArr = context.dataset.data;
          dataArr.map(data => {
            sum += data;
        });
           return Math.round(value*100/sum) + '%';
         },
        },
        legend: {
            display: true,
            position: "bottom",
            align:"start"
                   
    },
  },}

  // Portfolio
  const seriesTwo = [{
    name: 'series1',
    data: dp2
  }, {
    name: 'series2',
    data: dp1
  }];

  const optionTwo = {
    chart: {
      parentHeightOffset: 0,
      toolbar: { show: false },
      sparkline: { enabled: true }
    },
    colors: ['#85b6ff', '#506fd9'],
    grid: {
      borderColor: 'rgba(72, 94, 144, .1)',
      padding: {
        top: -20,
        left: 0
      },
      yaxis: { lines: { show: false } }
    },
    dataLabels: { enabled: false },
    stroke: {
      curve: 'straight',
      width: 1.5
    },
    xaxis: {
      type: 'numeric',
      min: 5,
      max: 90,
      tickAmount: 6,
      axisBorder: { show: false },
      labels: {
        style: {
          colors: '#6e7985',
          fontSize: '11px'
        }
      }
    },
    yaxis: { max: 180 },
    fill: {
      type: 'gradient',
      gradient: {
        opacityFrom: 0.5,
        opacityTo: 0,
      }
    },
    legend: { show: false },
    tooltip: { enabled: false }
  }

  // Events Categories
  const dataBar = {
    labels: ['FONDECYT REGULAR', 'FONDECYT INI.', 'FONDECYT POSTDOC', 'ANID COVID-19', 'Otros de I+D', 'Promedio de proyectos'],
    datasets: [{
      label: 'Hombres',
      data: [27, 16, 7, 14, 10, 15],
      backgroundColor: '#009ace',
      barPercentage: 0.45
    }, {
      label: 'Mujeres',
      data: [18, 15, 8, 9, 3, 11],
      backgroundColor: '#44d62c',
      barPercentage: 0.45
    }]
  };

  const optionBar = {
    indexAxis: 'y',
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        display: true
      }
    },
    scales: {
      x: {
        beginAtZero: true,
        max: 30,
        grid: {
          borderColor: '#e2e5ec',
          color: '#f3f5f9'
        },
        ticks: {
          font: {
            size: 11
          }
        }
      },
      y: {
        grid: {
          borderWidth: 0,
          color: '#f3f5f9'
        },
        ticks: {
          color: '#212830',
          font: {
            size: 14
          }
        }
      }
    }
  };
  const currentSkin = (localStorage.getItem('skin-mode')) ? 'dark' : '';
  const [skin, setSkin] = useState(currentSkin);
  const {origenIn,setOrigenIn} = props;
  const {mostrarWSE, setMostrarWSE} = React.useContext(MyEstadoGlobalContext);
  const {mostrarBD, setMostrarBD} = React.useContext(MyEstadoGlobalContext);
  const {mostrarProyectos, setMostrarProyectos} = React.useContext(MyEstadoGlobalContext);
  const {mostrarPI, setMostrarPI} = React.useContext(MyEstadoGlobalContext);
  const {mostrarHome, setMostrarHome} = React.useContext(MyEstadoGlobalContext);
  const {mostrarAyuda, setMostrarAyuda} = React.useContext(MyEstadoGlobalContext);

  useEffect(() => {
    //setOrigenIn(origenIn);
   // origenIn = 'Database' 
  //  setOrigenIn('Database')
  setMostrarWSE(false)
  setMostrarBD(false)
  setMostrarPI(false)
  setMostrarHome(false)
  setMostrarAyuda(false)
  
  setMostrarProyectos(true)


  }, [origenIn]);

  if (skin === 'dark') {
    optionBar.scales['x'].grid.color = '#222b41';
    optionBar.scales['x'].ticks.color = 'rgba(255,255,255,.65)';
    optionBar.scales['x'].grid.borderColor = '#222b41';
    optionBar.scales['y'].grid.color = '#222b41';
    optionBar.scales['y'].ticks.color = 'rgba(255,255,255,.65)';
  } else {
    optionBar.scales['x'].grid.color = '#edeff6';
    optionBar.scales['x'].ticks.color = '#42484e';
    optionBar.scales['x'].grid.borderColor = '#edeff6';
    optionBar.scales['y'].grid.color = '#edeff6';
    optionBar.scales['y'].ticks.color = '#42484e';
  }




  const switchSkin = (skin) => {
    if (skin === 'dark') {
      const btnWhite = document.getElementsByClassName('btn-white');

      for (const btn of btnWhite) {
        btn.classList.add('btn-outline-primary');
        btn.classList.remove('btn-white');
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName('btn-outline-primary');

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove('btn-outline-primary');
        btn.classList.add('btn-white');
      }
    }
  }

  switchSkin(skin);

  useEffect(() => {
    switchSkin(skin);
  }, [skin]);


  const paletaUA  = ['#009ace', '#44d62c', '#ffaa4d', '#ff7276', '#ea27c2', '#00b796', '#fdd635','#bd53ea','#8a69d4'];

  return (
    <React.Fragment>
      {/* <Header onSkin={setSkin} /> */}
      <div className="main main-app p-3 p-lg-4">
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item"><Link to="#">Dashboard</Link></li>
              <li className="breadcrumb-item active" aria-current="page">Proyectos</li>
            </ol>
    
          </div>
{/*           <div className="d-flex gap-2 mt-3 mt-md-0">
            <Button variant="" className="btn-white d-flex align-items-center gap-2">
              <i className="ri-printer-line fs-18 lh-1"></i>Print
            </Button>
            <Button variant="primary" className="d-flex align-items-center gap-2">
              <i className="ri-bar-chart-2-line fs-18 lh-1"></i>Generate<span className="d-none d-sm-inline"> Report</span>
            </Button>
          </div>  */}
        </div>

        <Row className="g-3">
               <Col md="6" xl="12">
                <Card className="card-one">
                  <Card.Header>
                    <Card.Title as="h6">Promedio de proyectos de I+D+i+e liderados por hombres y mujeres que obtuvieron financiamiento (reporte 2022)</Card.Title>
                    <Nav className="nav-icon nav-icon-sm ms-auto">
                      <Nav.Link href=""><i className="ri-refresh-line"></i></Nav.Link>
                      <Nav.Link href=""><i className="ri-more-2-fill"></i></Nav.Link>
                    </Nav>
                  </Card.Header>
                  <Card.Body>
                    <div className="chartjs-three mt-1">
                      <Bar data={dataBar} options={optionBar} />
                    </div>
                  </Card.Body>
                </Card>
              </Col>

          <Col sm="6" xl="3">
            <Card className="card-one">
              <Card.Body className="overflow-hidden px-0">
                <div className="crypto-item">
                      <div className="crypto-body">
                        
           
                    <h6 className="card-value"></h6>
                  </div>
                </div>
                <ReactApexChart
                  series={[{
                    data: ciencias
                  }]}
                  options={setOption(paletaUA[0], 2017,2022)}
                  type="area"
                  height={100}
                  className="apex-chart-ten"
                />
              </Card.Body>
              <Card.Footer className="card-footer-crypto pt-0">
                <div><strong>OCDE:</strong> Ciencias</div>
                <div><strong>Proyectos:</strong> 43</div>
              </Card.Footer>
            </Card>
          </Col>

          
          <Col sm="6" xl="3">
            <Card className="card-one">
              <Card.Body className="overflow-hidden px-0">
                <div className="crypto-item">
     {/*              <div className="avatar bg-twitter"><i className="cf cf-etc"></i></div> */}
                  <div className="crypto-body">
                  </div>
                </div>
                <ReactApexChart
                  series={[{
                    data: ingenieria
                  }]}
                  options={setOption(paletaUA[1], 2017,2022)}
                  type="area"
                  height={100}
                  className="apex-chart-ten"
                />
              </Card.Body>
              <Card.Footer className="card-footer-crypto pt-0">
              <div><strong>OCDE:</strong> Ingeniería, Industria y Construcción</div>
                <div><strong>Proyectos:</strong> 6</div>
              </Card.Footer>
            </Card>
          </Col>
          <Col sm="6" xl="3">
            <Card className="card-one">
              <Card.Body className="overflow-hidden px-0">
                <div className="crypto-item">
            
                  <div className="crypto-body">
{/*                     <label className="card-label">Bitcoin Cash <span>(BCH)</span></label>
                    <h6 className="card-value">$160.43</h6> */}
                  </div>
                </div>
                <ReactApexChart
                  series={[{
                    data: educacion
                  }]}
                  options={setOption(paletaUA[2], 2017,2022)}
                  type="area"
                  height={100}
                  className="apex-chart-ten"
                />
              </Card.Body>
              <Card.Footer className="card-footer-crypto pt-0">
              <div><strong>OCDE:</strong> Educación</div>
                <div><strong>Proyectos:</strong> 3</div>
              </Card.Footer>
            </Card>
          </Col>
          <Col sm="6" xl="3">
            <Card className="card-one">
              <Card.Body className="overflow-hidden px-0">
                <div className="crypto-item">
      
                  <div className="crypto-body">

                  </div>
                </div>
                <ReactApexChart
                  series={[{
                    data: humanidades
                  }]}
                  options={setOption(paletaUA[3], 2017,2022)}
                  type="area"
                  height={100}
                  className="apex-chart-ten"
                />
              </Card.Body>
              <Card.Footer className="card-footer-crypto pt-0">
              <div><strong>OCDE:</strong> Humanidades y Artes</div>
                <div><strong>Proyectos:</strong> 2</div>
              </Card.Footer>
            </Card>
          </Col>

          <Col sm="6" xl="3">
            <Card className="card-one">
              <Card.Body className="overflow-hidden px-0">
                <div className="crypto-item">
      
                  <div className="crypto-body">

                  </div>
                </div>
                <ReactApexChart
                  series={[{
                    data: servsociales
                  }]}
                  options={setOption(paletaUA[4], 2017,2022)}
                  type="area"
                  height={100}
                  className="apex-chart-ten"
                />
              </Card.Body>
              <Card.Footer className="card-footer-crypto pt-0">
              <div><strong>OCDE:</strong> Salud y Servicios Sociales</div>
                <div><strong>Proyectos:</strong> 7</div>
              </Card.Footer>
            </Card>
          </Col>

          <Col sm="6" xl="3">
            <Card className="card-one">
              <Card.Body className="overflow-hidden px-0">
                <div className="crypto-item">
      
                  <div className="crypto-body">

                  </div>
                </div>
                <ReactApexChart
                  series={[{
                    data: cienciassociales
                  }]}
                  options={setOption(paletaUA[5], 2017,2022)}
                  type="area"
                  height={100}
                  className="apex-chart-ten"
                />
              </Card.Body>
              <Card.Footer className="card-footer-crypto pt-0">
              <div><strong>OCDE:</strong> Ciencias Sociales, Enseñanza Comercial y Derecho</div>
                <div><strong>Proyectos:</strong> 16 </div>
              </Card.Footer>
            </Card>
          </Col>
          <Col sm="6" xl="3">
            <Card className="card-one">
              <Card.Body className="overflow-hidden px-0">
                <div className="crypto-item">
      
                  <div className="crypto-body">

                  </div>
                </div>
                <ReactApexChart
                  series={[{
                    data: agricultura
                  }]}
                  options={setOption(paletaUA[6], 2017,2022)}
                  type="area"
                  height={100}
                  className="apex-chart-ten"
                />
              </Card.Body>
              <Card.Footer className="card-footer-crypto pt-0">
              <div><strong>OCDE:</strong> Agricultura</div>
                <div><strong>Proyectos:</strong> 5 </div>
              </Card.Footer>
            </Card>
          </Col>
          <Col sm="6" xl="3">
            <Card className="card-one">
              <Card.Body className="overflow-hidden px-0">
                <div className="crypto-item">
      
                  <div className="crypto-body">

                  </div>
                </div>
                <ReactApexChart
                  series={[{
                    data: totales
                  }]}
                  options={setOption(paletaUA[7], 2017,2022)}
                  type="area"
                  height={100}
                  className="apex-chart-ten"
                />
              </Card.Body>
              <Card.Footer className="card-footer-crypto pt-0">
              <div><strong>Medición:</strong> Total global</div>
                <div><strong>Proyectos:</strong> 82 </div>
              </Card.Footer>
            </Card>
          </Col>

          {/* <Col md="6" xl="12">
                <Card className="card-one">
                  <Card.Header>
                    <Card.Title as="h6">Promedio de proyectos de I+D+i+e liderados por hombres y mujeres que obtuvieron financiamiento (reporte 2022)</Card.Title>
                    <Nav className="nav-icon nav-icon-sm ms-auto">
                      <Nav.Link href=""><i className="ri-refresh-line"></i></Nav.Link>
                      <Nav.Link href=""><i className="ri-more-2-fill"></i></Nav.Link>
                    </Nav>
                  </Card.Header>
                  <Card.Body>
                    <div className="chartjs-three mt-1">
                      <Bar data={dataBar} options={optionBar} />
                    </div>
                  </Card.Body>
                </Card>
              </Col> */}

              <Col xl="7">
            <Card className="card-one">
              <Card.Header>
                <Card.Title as="h6">Indicadores Institucionales</Card.Title>
                <Nav as="nav" className="nav-icon nav-icon-sm ms-auto">
                  <Nav.Link href=""><i className="ri-refresh-line"></i></Nav.Link>
                  <Nav.Link href=""><i className="ri-more-2-fill"></i></Nav.Link>
                </Nav>
              </Card.Header>
              <Card.Body className="p-3 p-xl-4">
                <Row className="g-3 mb-4">
                  {[
                    {
                      "bg": "twitter",
                      "icon": "ri-pie-chart-line",
                      "value": "Promedio Salario Anual",
                      "label": "+1.4% mujeres"
                    }, {
                      "bg": "success",
                      "icon": "ri-line-chart-fill",
                      "value": "Financiamiento proyectos liderados de I+D+i+e .",
                      "label": "+1.3% mujeres"
                    }
                  ].map((item, index) => (
                    <Col key={index}>
                      <div className="earning-item">
                        <div className={"earning-icon bg-" + item.bg}>
                          <i className={item.icon}></i>
                        </div>
                        <h4><span></span>{item.value}</h4>
                        <label>{item.label}</label>
                      </div>
                    </Col>
                  ))}
                </Row>

                <Table className="table-two mb-4" responsive>
                  <thead>
                    <tr>
                      <th>Indicador</th>
                      <th>Hombres</th>
                      <th>Mujeres</th>
                      </tr>
                  </thead>
                  <tbody>
                    {[
                      {
                        "date": "Académicas/os jerarquizadas/os con JCE.",
                        "sales": "329",
                        "earnings": "365",
                        "tax": "- $3,023.10",
                        "percent": "4.5"
                      }, {
                        "date": "Académicas/os jerarquizadas/os investigadores con JCE.",
                        "sales": "50",
                        "earnings": "37",
                        "tax": "- $2,780.00",
                        "percent": "3.8"
                      }, {
                        "date": "Proyectos liderados de I+D+i+e.",
                        "sales": "74",
                        "earnings": "53",
                        "tax": "- $2,540.60",
                        "percent": "3.3"
                      }, {
                        "date": "Participación en puestos de liderazgo que realizan I+D.",
                        "sales": "21",
                        "earnings": "15",
                        "tax": "- $2,144.60",
                        "percent": "2.9"
                      }, {
                        "date": "Solicitudes de patrocinio",
                        "sales": "26",
                        "earnings": "18",
                        "tax": "- $1,980.00",
                        "percent": "2.6"
                      }, {
                        "date": "Solicitudes de patrocinio aceptadas",
                        "sales": "26",
                        "earnings": "18",
                        "tax": "- $1,980.00",
                        "percent": "2.6"
                      }, {
                        "date": "EBCT de origen UA",
                        "sales": "1",
                        "earnings": "1",
                        "tax": "- $1,980.00",
                        "percent": "2.6"
                      }, {
                        "date": "Contratos de licencia",
                        "sales": "1",
                        "earnings": "2",
                        "tax": "- $1,980.00",
                        "percent": "2.6"
                      }



                    ].map((item, index) => (
                      <tr key={index}>
                        <td>{item.date}</td>
                        <td>{item.sales}</td>
                        <td >{item.earnings}</td>
                      {/*   <td className="text-danger">{item.tax}</td> */}
                      {/*   <td>{item.percent}%</td> */}
                      </tr>
                    ))}
                  </tbody>
                </Table>

           
              </Card.Body>
            </Card>
          </Col>
{/* 
          <Col xl="8">
            <Card className="card-one">
              <Card.Header>
                <Card.Title as="h6">Cryptocurrency Watchlist</Card.Title>
                <Nav className="nav-icon nav-icon-sm ms-auto">
                  <Nav.Link href=""><i className="ri-refresh-line"></i></Nav.Link>
                  <Nav.Link href=""><i className="ri-more-2-fill"></i></Nav.Link>
                </Nav>
              </Card.Header>
              <Card.Body>
                <ReactApexChart series={seriesOne} options={optionOne} type="area" height={290} className="apex-chart-nine mb-4" />

                <Row className="row-cols-auto gy-3 gx-5">
                  {[
                    {
                      "label": "Market Cap",
                      "value": "$14.5B"
                    }, {
                      "label": "Volume (24h)",
                      "value": "$6.8B"
                    }, {
                      "label": "Change",
                      "value": "$7.98"
                    }, {
                      "label": "Circulating Supply",
                      "value": "$18.59M"
                    }, {
                      "label": "All Time High",
                      "value": "$16.4K"
                    }
                  ].map((item, index) => (
                    <Col key={index}>
                      <label className="fs-xs mb-1 text-secondary">{item.label}</label>
                      <h4 className="ff-numerals text-dark mb-0">{item.value}</h4>
                    </Col>
                  ))}
                </Row>
              </Card.Body>
            </Card> */}
          



          <Col sm="6" xl="5">
            <Card className="card-one">
              <Card.Header>
                <Card.Title as="h6">Participación de académicas y/o investigadoras en puestos de liderazgo que realizan I+D.</Card.Title>
                <Nav className="nav-icon nav-icon-sm ms-auto">
                  <Nav.Link href=""><i className="ri-refresh-line"></i></Nav.Link>
                  <Nav.Link href=""><i className="ri-more-2-fill"></i></Nav.Link>
                </Nav>
              </Card.Header>
              <Card.Body>

             
                  <Doughnut data={dataPie} options={optionPie} />
               

                <Table className="table-five">
                  <tbody>
                    {[
                      {
                        "dot": "ua1",
                        "currency": "Dirección de institutos",
                        "amount": "Mujeres",
                        "percent": "+ 0.0%",
                        "success": true
                      }, {
                        "dot": "ua2",
                        "currency": "Coordinación de institutos",
                        "amount": "Mujeres",
                        "percent": "-25.0%",
                        "success": false
                      }, {
                        "dot": "ua3",
                        "currency": "Dirección de Centros de t+e",
                        "amount": "Mujeres",
                        "percent": "-100.0%",
                        "success": false
                      },
                      { "dot": "ua4",
                        "currency": "Coordinación de Centros de t+e",
                        "amount": "Mujeres",
                        "percent": "+100.0%",
                        "success": true
                      }, {
                        "dot": "ua5",
                        "currency": "VICE Rectorias I+D+i",
                        "amount": "Mujeres",
                        "percent": "-100.0%",
                        "success": false
                      }, {
                        "dot": "ua6",
                        "currency": "Dirección VRID",
                        "amount": "Mujeres",
                        "percent": "-50.0%",
                        "success": false
                      }, {
                        "dot": "ua7",
                        "currency": "Proyectos de investigación",
                        "amount": "Mujeres",
                        "percent": "+0.0%",
                        "success": true
                      }, {
                        "dot": "ua8",
                        "currency": "Proyectos de innovación",
                        "amount": "Mujeres",
                        "percent": "-100.0%",
                        "success": false
                      }, {
                        "dot": "gray-500",
                        "currency": "Promedio lider en I+D+i+e",
                        "amount": "Mujeres",
                        "percent": "-27.0%",
                        "success": false
                      }, {
                        "dot": "gray-500",
                        "currency": "N° Proyectos lider de I+D+i+e",
                        "amount": "Mujeres",
                        "percent": "-29.0%",
                        "success": false
                      }
                    ].map((item, index) => (
                      <tr key={index}>
                        <td>
                          <div><span className={"badge-dot bg-" + item.dot}></span> <span className="fw-medium">{item.currency}</span></div>
                        </td>
                        <td>{item.amount}</td>
                        <td>
                          <div className={"justify-content-end text-" + (item.success ? "success" : "danger")}>{item.percent} <i className={"ri-arrow-" + (item.success ? "up" : "down") + "-line"}></i></div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>


   {/*        <Col sm="6" xl="4">
            <Card className="card-one">
              <Card.Header>
                <Card.Title as="h6">Portfolio</Card.Title>
                <Nav className="nav-icon nav-icon-sm ms-auto">
                  <Nav.Link href=""><i className="ri-refresh-line"></i></Nav.Link>
                  <Nav.Link href=""><i className="ri-more-2-fill"></i></Nav.Link>
                </Nav>
              </Card.Header>
              <Card.Body>
                <div className="position-absolute p-1">
                  <label className="fw-medium fs-sm mb-1">Total Balance</label>
                  <h3 className="card-value"><span className="text-secondary">$</span>7,428.68</h3>
                </div>

                <ReactApexChart series={seriesTwo} options={optionTwo} type="area" height={195} className="apex-chart-ten mb-4" />

                <ListGroup as="ul" className="list-group-one">
                  {[
                    {
                      "icon": "btc",
                      "name": "Bitcoin",
                      "value": "0.7200",
                      "dollar": "2,907.71"
                    }, {
                      "icon": "eth",
                      "name": "Ethereum",
                      "value": "15.0030",
                      "dollar": "2,083.62"
                    }, {
                      "icon": "ltc",
                      "name": "Litecoin",
                      "value": "12.5021",
                      "dollar": "748.88"
                    }, {
                      "icon": "dash",
                      "name": "Dash",
                      "value": "16.5019",
                      "dollar": "1,521.97"
                    }, {
                      "icon": "btc",
                      "name": "Bitcoin Cash",
                      "value": "5.8120",
                      "dollar": "916.67"
                    }
                  ].map((item, index) => (
                    <ListGroup.Item key={index} as="li" className="px-0 d-flex align-items-center gap-2">
                      <div className="avatar bg-gray-300 text-secondary"><i className={"cf cf-" + item.icon}></i></div>
                      <div>
                        <h6 className="mb-0">{item.name}</h6>
                        <small>{item.icon.toUpperCase()}/USD</small>
                      </div>
                      <div className="ms-auto text-end">
                        <h6 className="ff-numerals mb-0">{item.value} {item.icon.toUpperCase()}</h6>
                        <small className="text-secondary">${item.dollar} USD</small>
                      </div>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              </Card.Body>
            </Card>
          </Col> */}

        </Row>

        <Footer />
      </div>
    </React.Fragment>
  )
}
