// import DinamicElement from "../DinamicElement";
// //import "./G3.css";
// import { Button, Card, Col, Row, Table, Nav, ProgressBar } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
/* import styled from "styled-components";
import useFetch from "react-fetch-hook"; */
//import useFetch2 from './useFetch2';
//import useFetch from './useFetch';
/* import axios from 'axios';
import ListsGraphics from "../../hooks/ListsGraphics"; */
//import ListaGraficos from "../ListaGraficos";
import NavegadorTradicional from "../NavegadorTradicional";
import chartManager from "../../hooks/chartManager";

var Qs = require('qs');

//Pagina que contiene los graficos
const CardsGraficos = ( props ) => {
const { Lista , consulta, SetId} = props;  
const [ Data, setData ] = useState([]);




chartManager.GetChartsVector(Lista, setData);

console.log("Lista ->: ")
console.log(Lista)
console.log("consulta: "+consulta)

/* useEffect(() => {

}, []); // Only re-run the effect if count changes
 */
   //const bloque = ListsGraphics(Lista);

  // console.log("Lista 2 ->: ")
   //console.log(Lista)

if (Array.isArray(Lista))
{
  
    return  <React.Fragment>      
            {/*  <div> Cargando resultados... 3,2,1.</div>    
            <div> Consulta: {consulta}</div>  
           <div> Si es array</div>      
           
             <ListsGraphics ids={Lista} />  */}


             <NavegadorTradicional Lista={Lista} SetId={SetId} /> 
  
            {/*<div> <h2>OK 2</h2> </div> 
            <div> <ListaGraficos  Lista={Lista}  opcion="uno"/></div>   */}


    </React.Fragment>

          }
          else
          {
            console.log("Retorno eterno erro")
            return  <React.Fragment>      
            <div> Ops! sin resultados, intente otra consulta... 3,2,1.</div>    
            <div> Consulta: {consulta}</div>
            <div> No  es array</div>
             {/* <div> <ListaGraficos  Lista={Lista}  opcion="uno"/></div>   */}
                   </React.Fragment>
          }
}

/* const Divisor = styled.div`
  width: 35%;
  aspect-ratio: 1/1;
  float: left;
  margin-left: 7.5%;
  margin-right: 7.5%;
  margin-bottom: 15px;
`;
 */
// function containsObject(obj, list) {
//   var x;
//   for (x in list) {
//       if (list.hasOwnProperty(x) && list[x] === obj) {
//           return true;
//       }
//   }

//   return false;
// }


export default CardsGraficos;